<section class="accordion">
  @for (item of items; track item; let i = $index) {
    <div
      class="accordion__item"
      [class.disabled]="item.disabled"
      [class.active]="expanded.has(i)"
      >
      <ng-container
        [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
      [ngTemplateOutletContext]="{
        $implicit: item,
        index: i,
        toggle: getToggleState(i)
      }"
      ></ng-container>
      <div
        class="accordion__content"
        [class.expanded]="expanded.has(i)"
        [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
        >
        @if (item?.content?.templateRef) {
          <ng-container
            *ngTemplateOutlet="item!.content!.templateRef"
          ></ng-container>
        }
      </div>
    </div>
  }
</section>

<ng-template #defaultHeader let-item let-index="index">
  <header
    class="accordion__header"
    (click)="item.disabled ? {} : toggleState(index)"
    >
    <ng-container
      *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"
    ></ng-container>
    <button
      class="accordion__toggle-btn"
      [disabled]="item.disabled"
      inlineSVG="img/icon-chevron-down.svg"
    ></button>
  </header>
  <ng-template #defaultTitle>
    <div class="accordion__title">{{ item?.title }}</div>
  </ng-template>
</ng-template>
